<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Input Data Pengajuan Pinjaman'" />

    <div>
      <h4 class="pb-4">Excel Input</h4>
      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="uploadedFile"
        @beforedelete="deleteFile($event)"
        ref="excelFile"
      ></VueFileAgent>
      <button-form
        :submit="submitExcelLoan"
        :buttonText="'Upload'"
        :loading="loading"
        class="pt-8"
      />

      <v-divider class="my-6" />

      <h4 class="pb-4">Form Input</h4>
      <form-loan :formLoan="formNewLoan" />
      <button-form :submit="submitLoan" :loading="loading" class="pt-8" />
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import StepperUser from "../../components/Stepper/StepperUser.vue";
import PopupRole from "../../components/Popup/PopupRole.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormLoan from "../../components/Form/FormLoan.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    StepperUser,
    PopupRole,
    PopupSuccess,
    PopupError,
    ButtonForm,
    FormLoan,
  },

  data() {
    return {
      steps: 1,
      uploadedFile: "",
      showDialog: false,
      showSuccessDialog: false,
      showErrDialog: false,
      loading: false,
      formNewLoan: {
        penyelenggara: "",
        idPinjaman: "",
        borrower: null,
        idPinjamanOld: "",
        namaPinjaman: "",
        idSyariah: "",
        ratingPengajuanPinjaman: "",
        nilaiPengajuanPinjaman: "",
        nilaiPermohonanPinjaman: "",
        jangkaWaktuPinjaman: "",
        statusPengajuanPinjaman: null,
        sukuBungaPinjaman: "",
        tanggalMulaiPendanaan: "",
        tanggalPengajuanPinjaman: "",
        penggunaanPinjaman: null,
        agunan: "",
        jenisAgunan: null,
        permintaanPinjaman: "",
        rasioPinjamanNilaiAgunan: "",
        rasioPinjamanAset: "",
        cicilanBulan: "",
        ratioPengajuanPinjaman: "",
        nilaiPlafond: "",
        satuanSukuBungaPinjaman: "",
        jenisBunga: "",
        tanggalMulaiPublikasiPinjaman: "",
        rencanaJangkaWaktuPublikasi: "",
        realisasiJangkaWaktuPublikasi: "",
        frekuensiPinjaman: "",
        report: null,
      },
    };
  },

  async mounted() {
    await store.dispatch("options/fetchLoanApplications");
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/list-submission");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    deleteFile(fileRecord) {
      this.$refs.excelFile.deleteFileRecord(fileRecord);
      this.uploadedFile = "";
    },
    async submitExcelLoan() {
      if (this.uploadedFile) {
        this.loading = true;
        let payload = new FormData();
        payload.append("table", "pengajuan");
        payload.append("json", "bulk");
        payload.append("file", this.uploadedFile.file);
        await store.dispatch("office/addExcel", payload).then((r) => {
          if (r) {
            console.log(r, "sukses");
            this.showSuccessDialog = !this.showSuccessDialog;
            this.loading = false;
          } else {
            this.showErrDialog = true;
            this.loading = false;
          }
        });
      } else {
        this.showErrDialog = true;
      }
    },
    async submitLoan() {
      this.uploadedFile = "";
      this.loading = false;
      try {
        console.log(this.formNewLoan);
        await store
          .dispatch("office/addNewLoan", this.formNewLoan)
          .then((r) => {
            if (r) {
              console.log(r, "sukses");
              this.showSuccessDialog = true;
              this.loading = false;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          });
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
  },
};
</script>
