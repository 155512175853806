<template>
  <v-form
    class="multi-col-validation"
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="formData.penyelenggara"
          :rules="[(v) => !!v || 'Mohon isi penyelenggara']"
          label="Penyelenggara"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.idPinjaman"
          :rules="[(v) => !!v || 'Mohon isi ID pinjaman']"
          label="ID Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <!-- <v-text-field
          v-model="formData.borrower"
          :rules="[(v) => !!v || 'Mohon pilih borrower']"
          label="Borrower"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field> -->
        <v-autocomplete
          v-model="formData.borrower"
          :items="statesBorrower"
          item-text="id_borrower"
          item-value="id"
          label="Borrower"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.idPinjamanOld"
          :rules="[(v) => !!v || 'Mohon isi ID pinjaman old']"
          label="ID Pinjaman Old"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.namaPinjaman"
          :rules="[(v) => !!v || 'Mohon isi nama pinjaman']"
          label="Nama Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.idSyariah"
          :items="idSyariahOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon isi ID syariah']"
          label="ID Syariah"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.ratingPengajuanPinjaman"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi rating pengajuan pinjaman']"
          label="Rating Pengajuan Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.nilaiPengajuanPinjaman"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi nilai pengajuan pinjaman']"
          label="Nilai Pengajuan Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.nilaiPermohonanPinjaman"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi nilai permohonan pinjaman']"
          label="Nilai Permohonan Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.jangkaWaktuPinjaman"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi jangka waktu pinjaman']"
          label="Jangka Waktu Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.statusPengajuanPinjaman"
          :items="statusPengajuanPinjamanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih status pengajuan pinjaman']"
          label="Status Pengajuan Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="formData.sukuBungaPinjaman"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi suku bunga pinjaman']"
          label="Suku Bunga Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalMulaiPendanaanMenu"
          v-model="tanggalMulaiPendanaanMenu"
          :close-on-content-click="false"
          :return-value.sync="formData.tanggalMulaiPendanaan"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.tanggalMulaiPendanaan"
              label="Tanggal Mulai Pendanaan"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.tanggalMulaiPendanaan"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalMulaiPendanaanMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalMulaiPendanaanMenu.save(
                  formData.tanggalMulaiPendanaan
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalPengajuanPinjamanMenu"
          v-model="tanggalPengajuanPinjamanMenu"
          :close-on-content-click="false"
          :return-value.sync="formData.tanggalPengajuanPinjaman"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.tanggalPengajuanPinjaman"
              label="Tanggal Pengajuan Pinjaman"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.tanggalPengajuanPinjaman"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalPengajuanPinjamanMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPengajuanPinjamanMenu.save(
                  formData.tanggalPengajuanPinjaman
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.penggunaanPinjaman"
          :items="penggunaanPinjamanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih penggunaan pinjaman']"
          label="Penggunaan Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.agunan"
          :items="agunanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon isi agunan']"
          label="Agunan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.jenisAgunan"
          :items="jenisAgunanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih jenis agunan']"
          label="Jenis Agunan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.permintaanPinjaman"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi permintaan pinjaman']"
          label="Permintaan Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.rasioPinjamanNilaiAgunan"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi rasio pinjaman nilai agunan']"
          label="Rasio Pinjaman Nilai Agunan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.rasioPinjamanAset"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi rasio pinjaman aset']"
          label="Rasio Pinjaman Aset"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.cicilanBulan"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi cicilan bulan']"
          label="Cicilan Bulan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.ratioPengajuanPinjaman"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi ratio pengajuan pinjaman']"
          label="Ratio Pengajuan Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.nilaiPlafond"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi nilai plafond']"
          label="Nilai Plafond"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.satuanSukuBungaPinjaman"
          :items="satuanSukuBungaPinjamanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon isi satuan suku bunga pinjaman']"
          label="Satuan Suku Bunga Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.jenisBunga"
          :items="jenisBungaOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon isi jenis bunga']"
          label="Jenis Bunga"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalMulaiPublikasiPinjamanMenu"
          v-model="tanggalMulaiPublikasiPinjamanMenu"
          :close-on-content-click="false"
          :return-value.sync="formData.tanggalMulaiPublikasiPinjaman"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.tanggalMulaiPublikasiPinjaman"
              label="Tanggal Mulai Publikasi Pinjaman"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.tanggalMulaiPublikasiPinjaman"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalMulaiPublikasiPinjamanMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalMulaiPublikasiPinjamanMenu.save(
                  formData.tanggalMulaiPublikasiPinjaman
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.rencanaJangkaWaktuPublikasi"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi rencana jangka waktu publikasi']"
          label="Rencana Jangka Waktu Publikasi (satuan Hari)"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.realisasiJangkaWaktuPublikasi"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi realisasi jangka waktu publikasi']"
          label="Realisasi Jangka Waktu Publikasi (satuan Hari)"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.frekuensiPinjaman"
          type="number"
          :items="frekuensiPinjamanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon isi frekuensi pinjaman']"
          label="Frekuensi Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <!-- <v-col cols="12" md="6">
        <v-select
          v-model="formData.report"
          :items="reportOptions"
          item-text="name"
          item-value="value"
          :rules="[(v) => !!v || 'Mohon pilih report']"
          label="Report"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col> -->
    </v-row>
  </v-form>
</template>

<script>
import store from "../../store/index.js";

export default {
  props: {
    formLoan: Object,
  },
  data: () => ({
    valid: true,
    loading: "false",
    formData: {
      penyelenggara: "",
      idPinjaman: "",
      borrower: null,
      idPinjamanOld: "",
      namaPinjaman: "",
      idSyariah: "",
      ratingPengajuanPinjaman: "",
      nilaiPengajuanPinjaman: "",
      nilaiPermohonanPinjaman: "",
      jangkaWaktuPinjaman: "",
      statusPengajuanPinjaman: null,
      sukuBungaPinjaman: "",
      tanggalMulaiPendanaan: "",
      tanggalPengajuanPinjaman: "",
      penggunaanPinjaman: null,
      agunan: "",
      jenisAgunan: null,
      permintaanPinjaman: "",
      rasioPinjamanNilaiAgunan: "",
      rasioPinjamanAset: "",
      cicilanBulan: "",
      ratioPengajuanPinjaman: "",
      nilaiPlafond: "",
      satuanSukuBungaPinjaman: "",
      jenisBunga: "",
      tanggalMulaiPublikasiPinjaman: "",
      rencanaJangkaWaktuPublikasi: "",
      realisasiJangkaWaktuPublikasi: "",
      frekuensiPinjaman: "",
      report: null,
    },
    tanggalMulaiPendanaanMenu: false,
    tanggalPengajuanPinjamanMenu: false,
    tanggalMulaiPublikasiPinjamanMenu: false,
    idSyariahOptions: [
      {
        label: "Ya",
        id: 1,
      },
      {
        label: "Tidak",
        id: 2,
      },
    ],
    borrowerOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
    statusPengajuanPinjamanOptions: [
      {
        name: "Dalam Proses Persetujuan",
        value: 1,
      },
      {
        name: "Distejui",
        value: 2,
      },
      {
        name: "Ditolak",
        value: 3,
      },
      {
        name: "Terdanai",
        value: 4,
      },
      {
        name: "Tidak Terdanai",
        value: 5,
      },
      {
        name: "Pencairan",
        value: 6,
      },
      {
        name: "Batal",
        value: 7,
      },
    ],
    penggunaanPinjamanOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
    agunanOptions: [
      {
        label: "Menggunakan Agunan",
        id: 1,
      },
      {
        label: "Tidak Menggunakan Agunan",
        id: 2,
      },
      {
        label: "Tidak Ada Data",
        id: 3,
      },
    ],
    jenisAgunanOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
    jenisBungaOptions: [
      {
        label: "Flat",
        id: 1,
      },
      {
        label: "Efektif",
        id: 2,
      },
    ],
    satuanSukuBungaPinjamanOptions: [
      {
        label: "Hari",
        id: 1,
      },
      {
        label: "Minggu",
        id: 2,
      },
      {
        label: "Bulan",
        id: 3,
      },
      {
        label: "Tahun",
        id: 4,
      },
    ],
    frekuensiPinjamanOptions: [
      {
        label: "Harian",
        id: 1,
      },
      {
        label: "Mingguan",
        id: 2,
      },
      {
        label: "Bulanan",
        id: 3,
      },
      {
        label: "Triwulan",
        id: 4,
      },
      {
        label: "Semester",
        id: 5,
      },
      {
        label: "Tahunan",
        id: 6,
      },
    ],
    reportOptions: [
      {
        name: "Ya",
        value: 0,
      },
      {
        name: "Tidak",
        value: 1,
      },
    ],
    statesBorrower: [],
  }),

  async mounted() {
    this.formData = this.formLoan;
    this.loading = "primary";

    await store.dispatch("options/fetchCollaterals");
    await store.dispatch("options/fetchLoanApplications");
    await store.dispatch("options/fetchLoans");
    await store.dispatch("office/fetchAllBorrower").then((r) => {
      if (r) this.loading = false;
    });

    this.jenisAgunanOptions = store.state.options.collaterals;
    this.statusPengajuanPinjamanOptions = store.state.options.loanApplications;
    this.penggunaanPinjamanOptions = store.state.options.loans;
    this.statesBorrower = store.state.office.allBorrower;
  },
};
</script>
